import { Component } from '@angular/core';
import { NavController} from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public listMenu = []
  constructor(public navCtrl: NavController, private router: Router, private analytics: AngularFireAnalytics) {
   
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.analytics.logEvent('page_view', {
          page_path: event.urlAfterRedirects
        });
      }
    });
    this.initializeApp()
  }

  initializeApp() {
   
  }
  ngOnInit() {

  }
  async irUrl(item: any) {
    const navigationExtras: NavigationExtras = { state: { video: item.url, isBackButton: true } };
    this.navCtrl.navigateForward(['ver-serie', item.nombre], navigationExtras);
  }

  async irUrlOtros(url: string, titulo: string) {
    const navigationExtras: NavigationExtras = { state: { video: url, isBackButton: true } };
    this.navCtrl.navigateForward(['ver-serie', titulo], navigationExtras);
  }

}
