export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyAtyuyUM5t-Q4SYnJqV1uRMtadbgzP-RNc",
    authDomain: "friki-series-18.firebaseapp.com",
    databaseURL: "https://friki-series-18-default-rtdb.firebaseio.com",
    projectId: "friki-series-18",
    storageBucket: "friki-series-18.appspot.com",
    messagingSenderId: "530259119140",
    appId: "1:530259119140:web:a46f3db3f269e3d1781cb1",
    measurementId: "G-SCGBZB40YX"
  }
};
