<ion-app>

  <ion-split-pane contentId="main-content">
    
    <ion-menu contentId="main-content" type="overlay">
      <ion-header>
        <ion-toolbar color="dark">
          <ion-title>Friki Series</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="itemAvatar">
          <ion-item lines="none">
            <ion-avatar slot="start">
              <img src="https://ik.imagekit.io/xqyzlihzqf/frikiSeries/menu.jpeg?tr=w-130,h-130" class="imagen-avatar"  loading="lazy" 
              decoding="async">
            </ion-avatar>          
          </ion-item>
        </div>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item  lines="none" menu-close [routerLink]="['/tabs/series']">
              <ion-icon color="tertiary" name="film-outline" slot="start"></ion-icon>
              <ion-label color="tertiary">Series</ion-label>
            </ion-item>   
            <ion-item  lines="none" menu-close [routerLink]="['/tabs/peliculas']">
              <ion-icon color="tertiary" name="videocam" slot="start"></ion-icon>
              <ion-label color="tertiary">Películas</ion-label>
            </ion-item>   
            <ion-item  lines="none" menu-close [routerLink]="['/tabs/buscar']">
              <ion-icon color="tertiary" name="search" slot="start"></ion-icon>
              <ion-label color="tertiary">Buscar</ion-label>
            </ion-item>   
            <ion-item  lines="none" menu-close [routerLink]="['/tabs/mi-lista']">
              <ion-icon color="tertiary" name="list" slot="start"></ion-icon>
              <ion-label color="tertiary">Mi lista</ion-label>
            </ion-item>
            <ion-item  lines="none" (click)="irUrlOtros('https://memorice-28e40.web.app/', 'Memorice retro')" menu-close>
              <ion-icon color="tertiary" name="game-controller" slot="start"></ion-icon>
              <ion-label color="tertiary">Memorice</ion-label>
            </ion-item>     
            <ion-item  lines="none" (click)="irUrlOtros('https://forms.gle/esfHe4AsgqXuFuCf7', 'Solicita tu serie')" menu-close>
              <ion-icon color="tertiary" name="create" slot="start"></ion-icon>
              <ion-label color="tertiary">Solicita tu series</ion-label>
            </ion-item>             
          </ion-menu-toggle>
    
        </ion-list>       
      </ion-content>
    </ion-menu>  
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
